export interface ICatalog {
  id: string;
  name: string;
  code: string;
  status: string;
  icon?:any;
  children?: ICatalog[]
}

export const keys = { label: 'name', value: 'id', children: 'children' }

export interface DropdownContext {
  content: string 
  value: string | number
}